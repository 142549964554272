var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"category-tree-container"},[_c('div',{staticClass:"header d-flex d-md-none flex-column"},[_c('v-row',{staticClass:"ma-2",attrs:{"no-gutters":"","justify":"space-between"}},[_c('img',{staticClass:"px-0 logo",attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? '40px' : '60px',"src":"/logo/logo.png","alt":"Logo Coop Shop"}}),_c('v-btn',{staticClass:"white pa-0",attrs:{"depressed":"","icon":"","aria-label":_vm.$t('common.close')},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$close")])],1)],1),_c('v-tabs',{staticClass:"px-2 mb-4",attrs:{"hide-slider":"","icons-and-text":"","centered":"","active-class":"activeTab","height":60},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{key:"categories",attrs:{"tab-value":0}},[_c('span',{staticClass:"text-none"},[_vm._v(_vm._s(_vm.$t("navbar.products")))]),_c('v-icon',[_vm._v("$basket")])],1),(_vm.isAuthenticated)?_c('v-tab',{key:"user",attrs:{"tab-value":1}},[_c('span',{staticClass:"text-none"},[_vm._v(_vm._s(_vm.$t("navbar.user")))]),_c('v-icon',[_vm._v("$profile")])],1):_vm._e(),_c('v-tab',{key:"info",attrs:{"tab-value":2}},[_c('span',{staticClass:"text-none"},[_vm._v(_vm._s(_vm.$t("navbar.links")))]),_c('v-icon',[_vm._v("$like")])],1)],1)],1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{attrs:{"value":0}},[_c('v-list',{staticClass:"category-tree px-2 px-sm-0"},[(_vm.navbarItems && _vm.navbarItems.proposals)?_vm._l((_vm.navbarItems.proposals),function(proposal){return _c('v-list-item',{key:proposal.proposalId,staticClass:"navbar-item d-flex d-md-none",attrs:{"link":"","to":{
              name: 'Category',
              params: { pathMatch: proposal.slug }
            }}},[_c('v-list-item-avatar',{staticClass:"mr-2",attrs:{"size":"20"}},[_c('img',{attrs:{"width":"20","height":"20","src":proposal.img,"alt":proposal.imgAlt,"title":proposal.imgDescription}})]),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(proposal.descr)+" ")])],1)}):_vm._e(),_vm._l((_vm.categories),function(category){return _c('v-list-group',{key:category.categoryId,staticClass:"category1",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-avatar',{staticClass:"mr-2",attrs:{"size":"28"}},[_c('img',{attrs:{"width":"28","height":"28","alt":category.name,"src":_vm.$ebsn.meta(category, 'category_info.ICON')}})]),_c('v-list-item-title',{staticClass:"font-weight-bold"},[_c('router-link',{attrs:{"aria-label":`${category.slug}`,"title":`${category.slug}`,"to":{
                  name: 'Category',
                  params: { pathMatch: category.slug }
                },"event":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.nop.apply(null, arguments)}}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)]},proxy:true}],null,true)},[_vm._l((category.categories),function(category2){return [_c('v-list-item',{key:category2.categoryId,staticClass:"category2",attrs:{"link":"","to":{
                name: 'Category',
                params: { pathMatch: category2.slug }
              }}},[_c('v-list-item-title',[_vm._v(_vm._s(category2.name))])],1)]})],2)})],2)],1),(_vm.isAuthenticated)?_c('v-tab-item',{attrs:{"value":1}},[_c('Dashboard',{staticClass:"px-2",attrs:{"drawer":true}})],1):_vm._e(),_c('v-tab-item',{attrs:{"value":2}},[_c('v-list',{staticClass:"info-tab px-2"},[_vm._l((_vm.primaryLinks),function(item,primaryLinkIdx){return [(item.templateNavItems?.length <= 0)?_c('v-list-item',{key:primaryLinkIdx,staticClass:"menuItem",attrs:{"href":item.itemUrl},on:{"click":_vm.clicked}},[_c('v-list-item-title',{staticClass:"ml-0",class:item.classes},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_c('v-list-group',{key:primaryLinkIdx,staticClass:"menuItem",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_c('a',{attrs:{"href":item.itemUrl,"event":"","aria-label":item.itemUrl,"title":item.itemUrl}},[_vm._v(" "+_vm._s(item.name)+" ")])])]},proxy:true}],null,true)},_vm._l((item.templateNavItems),function(child,childIdx){return _c('div',{key:childIdx},[_c('v-list-item',{attrs:{"link":"","href":child.itemUrl},on:{"click":_vm.clicked}},[_c('v-list-item-title',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(child.name)+" ")])],1),(child.templateNavItems?.length)?_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},_vm._l((child.children),function(child2,idx3){return _c('v-list-item',{key:idx3,staticClass:"text-caption default--text ml-3",class:child2.classes,attrs:{"link":"","href":child2.itemUrl || '#'},on:{"click":_vm.clicked}},[_c('span',{class:child2.classes},[_vm._v(_vm._s(child2.name))])])}),1):_vm._e()],1)}),0)]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }