<template>
  <v-row no-gutters class="grey" align="center">
    <v-col cols="1" class="d-flex justify-center">
      <v-icon x-large @click="openAddressSelector"
        >${{ $t(`navbar.service.${getDeliveryServiceId}.fontIcon`) }}</v-icon
      >
    </v-col>
    <v-col cols="11" class="d-flex align-center">
      <div class="d-flex flex-column justify-start w-100">
        <div class="info-text d-flex" @click="openAddressSelector">
          <span>{{ serviceName }} </span>
          <span
            class="font-weight-medium w-100 d-flex justify-space-between mr-5"
          >
            {{ shippingAddress }}<v-icon small right>$edit</v-icon></span
          >
        </div>
        <div
          class="info-text d-flex"
          :disabled="!editableTimeslot"
          @click="openTimeslotSelector"
        >
          <span>{{ $t("cart.cartInfo.timeslot") }} </span>
          <span
            class="font-weight-medium ml-1 w-100 d-flex justify-space-between mr-5"
            >{{ timeslot }}<v-icon small right>$edit</v-icon></span
          >
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<style lang="scss" scoped>
.info-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  .font-weight-medium {
    font-weight: 600 !important;
  }
}
.right-border {
  border-right: 1px solid $secondary;
}
</style>
<script>
import { mapState } from "vuex";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
export default {
  name: "MiniCartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  }
};
</script>
