<template>
  <div class="menu-link-list-container d-flex flex-column h-100">
    <div class="header pa-2">
      <v-row no-gutters justify="space-between">
        <img
          class="pa-0 logo ma-2"
          :height="$vuetify.breakpoint.smAndDown ? '42px' : '63'"
          src="/logo/logo.png"
          alt="Logo Coop Shop"
        />
        <v-btn
          depressed
          icon
          class="pa-0"
          :aria-label="$t('common.close')"
          @click="$emit('close')"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </v-row>
      <!-- <h4 class="secondary--text ma-2 d-none d-sm-inline">
        {{ $t("navbar.categoryTitle") }}
      </h4> -->
      <!-- <v-tabs
        v-model="selectedTab"
        class="px-2 mb-4 d-inline d-sm-none"
        hide-slider
        active-class="activeTab"
      >
        <v-tab key="categories">
          <h6 class="text-none">{{ $t("navbar.products") }}</h6>
        </v-tab>
        <v-tab key="info" v-if="$vuetify.breakpoint.xs">
          <h6 class="text-none">{{ $t("navbar.links") }}</h6>
        </v-tab>
      </v-tabs> -->
    </div>

    <div v-if="links" class="links-grey">
      <v-expansion-panels
        v-model="openedPanel"
        accordion
        flat
        tile
        :focusable="selectedTab"
        class="px-4"
      >
        <v-expansion-panel v-for="(link, index) in links" :key="index">
          <v-expansion-panel-header>
            <template v-slot:actions>
              <v-icon>$chevronDown</v-icon>
            </template>
            <span class="text--color font-weight-bold text-uppercase">{{
              link.name
            }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="mt-2">
            <template v-for="child in link.templateNavItems">
              <template
                v-if="
                  child.templateNavItems && child.templateNavItems.length === 0
                "
              >
                <a
                  v-if="child.itemUrl && child.itemUrl !== ''"
                  :aria-label="child.name"
                  :title="child.name"
                  :key="child.templateNavItemId"
                  :href="child.itemUrl"
                  class="d-block default--text text-decoration-none py-2"
                  :class="[child.classStyle, { 'no-evt': index == 2 }]"
                  :target="child.target"
                  @click="onLinkClick"
                >
                  <span :class="child.classStyle">{{ child.name }}</span>
                </a>
                <span
                  v-else
                  :key="child.templateNavItemId"
                  class="d-block default--text text-decoration-none py-2"
                  :class="child.classStyle"
                  >{{ child.name }}</span
                >
              </template>
              <div v-else :key="child.templateNavItemId">
                <div class="font-weight-bold text-uppercase mt-4 mb-1">
                  {{ child.name }}
                </div>
                <template v-for="child2 in child.templateNavItems">
                  <a
                    v-if="child2.itemUrl && child2.itemUrl !== ''"
                    :aria-label="child.name"
                    :title="child.name"
                    :key="child2.templateNavItemId"
                    :id="`footer-menu-${child2.templateNavItemId}`"
                    :href="child2.itemUrl"
                    class="default--text text-decoration-none"
                    :class="child.classStyle"
                    :target="child2.target || '_self'"
                    @click="onLinkClick"
                  >
                    <img
                      :src="child2.iconSource"
                      :alt="child2.name"
                      class="mr-2"
                    />
                  </a>
                  <img
                    v-else
                    :key="child2.templateNavItemId"
                    :src="child2.iconSource"
                    :alt="child2.name"
                    class="mr-2"
                  />
                </template>
              </div>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- <div no-gutters class="py-1 my-2 parent">
      <a
        link
        class="default--text font-weight-bold text-uppercase mx-10"
        @click="openCookies()"
      >
        Gestione Cookies
      </a>
    </div> -->
    <v-divider class="grey w-100 mb-1" />
    <!-- <v-row class="py-8">
      <v-col
        v-if="!isCordova"
        cols="12"
        sm="6"
        class="d-flex"
        :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-start'"
      >
        <a
          aria-label="Scarica l'applicazione Coop su App store"
          title="Scarica l'applicazione Coop su App store"
          href="https://apps.apple.com/it/app/al%C3%AC/id1440578549"
          target="_blank"
        >
          <img
            class="d-block"
            src="/img_layout/footer/app-store.png"
            alt="Immagine app store"
          />
        </a>
        <a
          aria-label="Scarica l'applicazione Coop su Play store"
          title="Scarica l'applicazione Coop su Play store"
          href="https://play.google.com/store/apps/details?id=it.digitelematica.ebsn.ccno"
          target="_blank"
        >
          <img
            class="d-block ml-3"
            src="/img_layout/footer/google-play.png"
            alt="Immagine google play"
          />
        </a>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="d-flex align-center pa-2 logo-gap"
        :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'"
      >
        <div
          v-for="(item, idx) in socialItems"
          :key="idx"
          class="d-flex align-center px-2"
        >
          <a
            :aria-label="`Coop ${item.icon}`"
            :title="`Coop ${item.icon}`"
            :href="item.url"
            target="_blank"
            class="text-decoration-none"
            @click="onLinkClick"
          >
            <img class="d-block" :src="item.iconSource" :alt="`${item.icon}`" />
          </a>
        </div>
      </v-col>
    </v-row> -->
    <div
      class="align-center d-inline-flex py-4 primary white--text rounded-sm w-100 mt-auto mb-0"
      :column="$vuetify.breakpoint.xs"
    >
      <span
        class="text-caption mx-3 mx-sm-10"
        v-html="$t('footer.copyright', [version])"
      />
    </div>

    <!-- <div class="section default--text last text-center" v-if="isCordova">
      <i18n path="footer.version" tag="span" class="my-1 child pl-4">
        {{ version }}
      </i18n>
    </div> -->
  </div>
</template>
<style lang="scss">
.menu-link-list-container {
  .theme--light.v-expansion-panels .v-expansion-panel {
    background-color: var(--v-grey-lighten2);
  }
  .theme--light.v-expansion-panels .v-expansion-panel:last-child {
    .v-expansion-panel-header {
      span {
        color: var(--v-black-base) !important;
      }
    }
  }

  .header {
    background-color: $white !important;
  }

  .section {
    border-bottom: 1px solid #e9e9e9;
    padding: 12px 0;
    &:first-of-type {
      padding-top: 0 !important;
    }
  }
  .section.last {
    border-bottom: none;
  }
  .icon-close {
    font-size: 24px !important;
    @media only screen and (max-width: 350px) {
      font-size: 22px;
    }
  }
  .header {
    border-bottom: 1px solid #d5d5d5;
  }
  .v-expansion-panel {
    border-bottom: 1px solid #d5d5d5;
  }
}
.is-cordova {
  // .menu-link-list-container {
  //   background-color: var(--v-grey-lighten2);
  // }
  .links-list {
    background-color: var(--v-grey-lighten2);
  }
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";
import downloadDocument from "~/mixins/downloadDocument";

export default {
  mixins: [clickHandler, downloadDocument],
  props: { links: { type: Array, required: true } },
  data() {
    return {
      openedPanel: undefined,
      selectedTab: null
    };
  },
  computed: {
    version() {
      return global.config.version;
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    onLinkClick(e) {
      var url = e.currentTarget.href;
      let fileName = "";
      this.$emit("close");
      this.$nextTick(() => {
        console.log("EVENT:", e);
        if (
          this.$platform_is_cordova &&
          e.currentTarget.href.endsWith(".pdf")
        ) {
          fileName = url.substring(url.lastIndexOf("/") + 1, url.length);
          fileName = fileName.substring(0, fileName.length - 4);
          this.handleReceiptDownload(url, fileName);
        } // else if (e.target.className.includes("cookiebot-widget")) {
        //   if (window.Cookiebot) {
        //     window.Cookiebot.show();
        //   }
        else if (e.target.className.includes("cookie-widget-trigger")) {
          if (window.Cookiebot) {
            window.Cookiebot.show();
          }
        } else {
          this.clicked(e);
        }
      });
    },
    goToExternalPage(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        //if app open on _system browser
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    }
    // openCookies() {
    //   if (window.Cookiebot) {
    //     window.Cookiebot.show();
    //   }
    // }
  },
  mounted() {
    if (this.links && this.links.length) {
      this.openedPanel = this.links.length - 1;
    }
  }
};
</script>
