<style lang="scss"></style>
<script>
import { mapGetters } from "vuex";

export default {
  name: "FreshChatWidget",
  data() {
    return {
      chatTemplate: {}
    };
  },
  computed: {
    ...mapGetters({
      storeId: "cart/getStoreId",
      user: "cart/getUser"
    })
  },
  methods: {
    initFreshChat() {
      let freshKeyToken;
      if (this.storeId == 1)
        freshKeyToken = process.env.VUE_APP_FRESHCHAT_SITE_KEY_STORE_1;
      if (this.storeId == 2)
        freshKeyToken = process.env.VUE_APP_FRESHCHAT_SITE_KEY_STORE_2;

      if (freshKeyToken) {
        window.fcWidget.init({
          config: {
            cssNames: {
              widget: "custom_fc_frame"
            },
            headerProperty: {
              direction: "ltr" //will move widget to left side of the screen
            }
          },
          token: freshKeyToken,
          host: "https://wchat.eu.freshchat.com",
          externalId: this.user.userId,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email
        });
      }
    },
    storeChanged() {
      let _this = this;
      window.fcWidget.destroy();
      setTimeout(function() {
        _this.initFreshChat();
      }, 500);
    }
  },
  mounted() {
    if (window.fcWidget) {
      this.initFreshChat();
    }
  },
  watch: {
    storeId(val, oldVal) {
      if (val !== oldVal) {
        this.storeChanged();
      }
    }
  },
  beforeDestroy() {
    window.fcWidget.destroy();
  }
};
</script>
