<template>
  <v-app
    v-if="i18nLoaded"
    :class="[
      getKeyboardOpen ? classes + ' keyboard-open' : classes,
      { 'platform-ios': platformIos }
    ]"
  >
    <!-- <NewsBannerSlider v-if="isAuthenticated" /> -->
    <FreshChatWidget v-if="isAuthenticated && !isCordova" />
    <NewsAlert v-if="isAuthenticated" />
    <Navbar
      ref="navBar"
      :key="key"
      :primaryLinks="primaryLinks"
      :applicationConfig="applicationConfig"
      :navbarItems="navbarItems"
    />
    <v-navigation-drawer
      v-model="drawerLeft"
      touchless
      left
      app
      clipped
      width="300"
      class="categories"
    >
      <CategoryTree
        v-if="!isCordova || drawerLeft"
        :primaryLinks="primaryLinks"
        :applicationConfig="applicationConfig"
        :navbarItems="navbarItems"
        @close="drawerLeft = false"
      />
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="drawerRight"
      disable-resize-watcher
      touchless
      temporary
      clipped
      right
      app
      width="650"
      id="cart-right-drawer"
      class="cart-drawer white"
      v-if="!isCordova"
    >
      <div class="cart-info" v-if="drawerRight">
        <CartInfoCompact :isCart="true" class="infos" />
        <CartItemList class="items-list" />
        <CartSummary
          class="summary py-0"
          :class="{ 'cart-item-list-no-checkout-osx': iosMobile }"
        />
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer
      class="links-list"
      v-if="cordovaPlatform"
      v-model="drawerLinks"
      touchless
      right
      clipped
      app
      width="360"
      @close="drawerLinks = false"
    >
      <MenuLinksList
        v-if="drawerLinks"
        :links="footerLinks"
        @close="drawerLinks = false"
      />
    </v-navigation-drawer>
    <v-main>
      <ebsn-style
        :target="applicationConfig"
        path="category_advanced.CSS_STYLE"
      />
      <div class="content-wrap">
        <v-skeleton-loader
          type="actions, image, article,image"
          v-if="loading"
          :loading="loading"
        ></v-skeleton-loader>
        <router-view v-else></router-view>
      </div>
      <category-block
        v-if="applicationConfig"
        :target="applicationConfig"
        position="tutorial"
        class="category-block category-block-tutorial"
      />
      <Footer
        :links="footerLinks"
        :links2="footer2Links"
        :applicationConfig="applicationConfig"
      />
    </v-main>

    <ScrollTop />
    <SsoUserCard v-if="!isCordova" />
    <!-- <GiftCertificateWindow /> -->
  </v-app>
</template>
<script>
// import VueInstagram from "vue-instagram";
import CartSummary from "@/components/cart/CartSummary.vue";
import CartInfoCompact from "@/components/cart/CartInfoCompact.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import CategoryTree from "@/components/navigation/CategoryTree.vue";
import CheckDisclaimerDialog from "@/components/profile/CheckDisclaimerDialog.vue";
import SsoUserCard from "@/components/profile/SsoUserCard.vue";
import FreshChatWidget from "@/components/widget/FreshChatWidget.vue";

import login from "~/mixins/login";
import version from "~/mixins/version";
import deliveryReactive from "~/mixins/deliveryReactive";

import UserService from "~/service/userService";
import CategoryService from "~/service/categoryService";

import { mapActions, mapGetters, mapState } from "vuex";
import findIndex from "lodash/findIndex";

import ScrollTop from "./components/ScrollTop.vue";
import analyticsService from "./commons/service/analyticsService";
import MenuLinksList from "@/components/navigation/MenuLinksList.vue";

import NewsAlert from "@/components/news/NewsAlert.vue";
import { setLocale } from "@/plugins/i18n";

//TEST
// import testMenuData from "/public/json/testMenuLinks.json";
//TEST

export default {
  name: "App",
  components: {
    // VueInstagram,
    CategoryTree,
    FreshChatWidget,
    CartInfoCompact,
    CartSummary,
    CartItemList,
    NewsAlert,
    // CategoryBlock,
    Footer: () =>
      process.env.CORDOVA_PLATFORM
        ? import("@/components/navigation/BottomNavBar.vue")
        : import("@/components/navigation/Footer.vue"),
    Navbar: () =>
      process.env.CORDOVA_PLATFORM
        ? import("@/components/navigation/NavbarMobile.vue")
        : import("@/components/navigation/Navbar.vue"),
    SsoUserCard,
    ScrollTop,
    MenuLinksList
    // ,
    // GiftCertificateWindow
  },
  mixins: [login, version, deliveryReactive],
  data() {
    return {
      loading: true,
      primaryLinks: [],
      categoryTreeLinks: [],
      footerLinks: [],
      footer2Links: [],
      applicationConfig: null,
      i18nLoaded: false,
      key: 1,
      classes: process.env.CORDOVA_PLATFORM ? "is-cordova" : "",
      platformIos: false,
      keyboardOpen: false,
      iosMobile: false,
      attValue: false
    };
  },
  metaInfo: {
    title: "Coop Shop",
    titleTemplate: "%s - Coop Shop",
    meta: [
      { charset: "utf-8" },
      {
        vmid: "description",
        name: "description",
        content:
          "Coop Shop è il nuovissimo servizio di Coop Shop che consente di fare acquisti su un vasto assortimento di prodotti in totale comodità da casa, con semplicità e rapidità"
      },
      { name: "viewport", content: "width=device-width, user-scalable=no" },
      { name: "author", content: "Digitelematica s.r.l." },
      // Open Graph Metas
      {
        vmid: "og:locale",
        property: "og:locale",
        content: "it_IT"
      },
      {
        vmid: "og:title",
        property: "og:title",
        content: "%s - Coop Shop"
      },
      {
        vmid: "og:type",
        property: "og:type",
        content: "website"
      },
      {
        vmid: "og:url",
        property: "og:url",
        content: location.href
      },
      {
        vmid: "og:image",
        property: "og:image",
        content:
          window.location.host + global.config.socialLogo ||
          "/logo/social-logo.png"
      },
      {
        vmid: "og:image:alt",
        property: "og:image:alt",
        content:
          "Spesa Online è il nuovissimo servizio di che consente di fare acquisti su un vasto assortimento di prodotti in totale comodità da casa, con semplicità e rapidità"
      },
      {
        vmid: "og:site_name",
        property: "og:site_name",
        content: "Coop Shop"
      },
      // Twitter Metas
      {
        name: "twitter:card",
        content: "summary_large_image"
      },
      {
        name: "twitter:title",
        content: "%s - Coop Shop"
      },
      {
        name: "twitter:image",
        content:
          window.location.host + global.config.socialLogo ||
          "/logo/social-logo.png"
      },
      {
        name: "twitter:url",
        content: location.href
      }
    ]
  },
  computed: {
    ...mapGetters({
      needRestart: "needRestart",
      isAuthenticated: "cart/isAuthenticated",
      getWarehouse: "cart/getWarehouse",
      getTimeslot: "cart/getTimeslot"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    drawerLeft: {
      get() {
        return this.$store.state.app.drawerLeft;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    drawerRight: {
      get() {
        return this.$store.state.app.drawerRight;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerRight", value);
      }
    },
    drawerLinks: {
      get() {
        return this.$store.state.app.drawerLinks;
      },
      set(value) {
        this.$store.commit("app/updatedDrawerLinks", value);
      }
    },
    getUserId() {
      return this.$store.state.cart.cart.user
        ? this.$store.state.cart.cart.user.userId
        : undefined;
    },
    getKeyboardOpen: function() {
      return this.keyboardOpen;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    navbarItems() {
      let items = this.applicationConfig?.categoryBlocks?.find(
        categoryBlock => categoryBlock.templateBlock.codInt == "navbar"
      );

      // check happy hour
      if (this.isAuthenticated) {
        let happyHourWarehouseEnabled = this.$ebsn.meta(
          this.getWarehouse,
          "metaData.warehouse_info.ENABLE_PROMO_HAPPY_HOUR"
        );
        let tomorrow = this.$dayjs().add(1, "day");
        let timeslotDate = this.$dayjs(this.getTimeslot.date);
        // controllare se la timeslot date è AxA o AxB
        if (
          !happyHourWarehouseEnabled ||
          timeslotDate.isAfter(tomorrow, "day")
        ) {
          if (items && items.proposals && items.proposals.length > 0) {
            items.proposals = items.proposals.filter(proposal => {
              return proposal.slug != "offerte-happy-hour";
            });
          }
        }
      }

      return items;
    }
  },
  methods: {
    ...mapActions({
      updateAuth: "cart/updateAuth",
      resetFilters: "category/resetFilters",
      updateLastStartTime: "app/updateLastStartTime",
      updateFingerprint: "app/updateFingerprint",
      setApplicationConfig: "custom/setApplicationConfig"
    }),
    checkOsx() {
      var userAgent = [];
      var version = [];
      var versionNumber = 0;
      if (navigator.userAgent.indexOf("iPhone") > -1) {
        userAgent = navigator.userAgent.split(" ");
        userAgent.filter(ua => {
          if (ua.indexOf("Version/") > -1) {
            version = ua.split("/");
          }
        });
        versionNumber = parseFloat(version[1]);
        if (version[0] == "Version") {
          if (versionNumber < 15) {
            this.iosMobile = true;
          } else {
            this.iosMobile = false;
          }
        } else {
          this.iosMobile = false;
        }
      }
    },

    async onResume() {
      var _this = this;
      var hash;
      global.EventBus.$emit("resume");
      setTimeout(async function() {
        hash = findIndex(global.config.hashNotReload, function(o) {
          return window.location.hash.indexOf(o) !== -1;
        });
        var needRestart =
          new Date().getTime() - _this.$store.state.app.lastStartTime >
          global.config.reloadAfterMillis;
        if (hash === -1 && needRestart) {
          global.EventBus.$emit("restart");
        } else {
          if (
            hash === -1 &&
            window.sessionStorage.getItem("is-authenticated")
          ) {
            await _this.loadCart();
          }
          global.EventBus.$emit("reload");
        }
      }, 100);
    },
    async handleLogin() {
      this.doLogin("response419");
    },
    async onDeepLinks(path, queryString) {
      var _this = this;
      var currentQuery = {};
      console.log("onDeepLinks :::: ", path);
      console.log("onDeepLinks queryString :::: ", queryString);
      console.log("_this.$router :::: ", _this.$router);
      setTimeout(async function() {
        if (queryString) {
          currentQuery.q = queryString.replace("q=", "").replace(/%20/g, " ");
          console.log("onDeepLinks currentQuery :::: ", currentQuery);
          _this.$router.push({
            path: "/search",
            name: "Search",
            query: currentQuery
          });
        } else {
          _this.$router.push(path);
        }
      }, 100);
    },
    resolveRoute(data) {
      var _this = this;
      var currentQuery = {};
      console.log("resolveRoute :::: ", data);
      if (global.config.domainWhitelist.includes(data.host)) {
        let queryString = data.queryString;
        let resolved = this.$router.resolve({
          path: data.path
        });
        if (
          resolved &&
          resolved.route.matched.length > 0 &&
          resolved.route.name != "DefaultRoute"
        ) {
          setTimeout(async function() {
            if (queryString) {
              var keyValPairs = queryString.split("&");
              for (var pairNum in keyValPairs) {
                var key = keyValPairs[pairNum].split("=")[0];
                if (key.length > 0) {
                  if (typeof currentQuery[key] === "undefined")
                    currentQuery[key] = "";
                  currentQuery[key] = keyValPairs[pairNum].split("=")[1];
                }
              }
              currentQuery.q = queryString
                .replace("q=", "")
                .replace(/%20/g, " ");
              console.log("onDeepLinks currentQuery :::: ", currentQuery);
              _this.$router.push({
                path: data.path,
                query: currentQuery
              });
            } else {
              _this.$router.push(data.path);
            }
          }, 100);
        } else {
          if (this.$platform_is_cordova && data.url.indexOf("http") > -1) {
            //if app open on _system browser
            window.cordova.InAppBrowser.open(data.url, "_system");
          }
        }
      } else {
        if (this.$platform_is_cordova && data.url.indexOf("http") > -1) {
          //if app open on _system browser
          window.cordova.InAppBrowser.open(data.url, "_system");
        }
      }
    },
    onAppTrackingTransparency() {
      var _this = this;
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      if (
        _this.$platform_is_cordova &&
        platform == "ios" &&
        window.plugins.impacTracking
      ) {
        console.log("Calling onAppTrackingTransparency");
        window.plugins.impacTracking.trackingAvailable(
          function(available) {
            if (!available) {
              console.log(
                "Tracciamento non disponibile o disabilitato dall'utente"
              );
              window.plugins.impacTracking.canRequestTracking(
                function(result) {
                  // Show results in the console
                  console.log(result);
                  if (result == true) {
                    // eslint-disable-next-line no-undef
                    Cookiebot.hide();
                    window.plugins.impacTracking.requestTracking(
                      undefined,
                      function(res) {
                        console.log(res);
                        _this.attValue = res;
                        if (res == true) {
                          // eslint-disable-next-line no-undef
                          Cookiebot.submitCustomConsent(true, true, true);
                        }
                        if (res == false) {
                          // eslint-disable-next-line no-undef
                          Cookiebot.hide();
                        }
                      },
                      function(err) {
                        console.log(err);
                        //TODO: tracking non abilitato
                      }
                    );
                  }
                },
                function(err) {
                  console.log(err);
                  //TODO: c'è stato un errore: tracking non abilitato
                }
              );
            } else {
              window.plugins.impacTracking.requestTracking(
                undefined,
                function(res) {
                  console.log(
                    "Calling onAppTrackingTransparency; already defined"
                  );
                  console.log("AppTracking enabled: " + res);
                  _this.attValue = res;
                  // eslint-disable-next-line no-undef
                  var stats = Cookiebot.consent.statistics;
                  // eslint-disable-next-line no-undef
                  var mrkg = Cookiebot.consent.marketing;
                  // eslint-disable-next-line no-undef
                  var pref = Cookiebot.consent.preferences;
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(pref, stats, mrkg);
                  if (res == false) {
                    // eslint-disable-next-line no-undef
                    Cookiebot.hide();
                  }
                },
                function(err) {
                  console.log(err);
                }
              );
            }
          },
          function(err) {
            console.log(err);
          }
        );
      }
    },
    async refreshLogin() {
      if (global.config.pushNotificationEnabled) {
        console.log("REFRESH LOGIN - REGISTER FIREBASE DEVICE");
        // Al Deviceready facciamo un refresh login per controllare se l'utente è ancora collegato
        // await SecurityService.refreshLogin();
        // await this.registerFirebaseDevice();
      }
    },
    ...mapActions({
      loadCart: "cart/loadCart"
    }),
    isPlatformIos() {
      if (this.isCordova && window.cordova.platformId.toLowerCase() === "ios") {
        this.platformIos = true;
      }
    },
    async checkMissingDisclaimer() {
      let response = await UserService.checkUserDisclaimer(2);
      if (
        response &&
        response.registrationModules &&
        response.registrationModules.length > 0
      ) {
        await this.openDialog(CheckDisclaimerDialog, {
          waitForResult: true,
          fullscreen: false
        });
      }
    },
    async reload() {
      try {
        this.applicationConfig = await CategoryService.getCategoryBySlug(
          "application"
        );
        if (this.applicationConfig.templateNavItems) {
          this.primaryLinks = this.applicationConfig.templateNavItems.header;
          this.footerLinks = this.applicationConfig.templateNavItems.footer;
          this.footer2Links = this.applicationConfig.templateNavItems.footer2;
        }
        this.setApplicationConfig(
          this.$ebsn.meta(this.applicationConfig, "template_model", {})
        );
      } catch (err) {
        console.log(err);
      }
    }
  },
  async created() {
    await setLocale("it"); //abilitare se si vuole usare la risorsa di localizzazione remota
    this.i18nLoaded = true;
    var _this = this;
    //await this.fetchPrimaryLinks();

    //await this.fetchFooterLinks();
    // await this.fetchCategory();
    this.reload();
    try {
      if (
        window.sessionStorage.getItem("is-authenticated") ||
        window.sessionStorage.getItem("X-Ebsn-Session") ||
        window.localStorage.getItem("X-Ebsn-Account") ||
        window.location.href.indexOf("checkUser") !== -1
      ) {
        await _this.loadCart();
      }
      //analyticsService.initCustomDimension();
      let device = "web";
      if (typeof cordova !== "undefined") {
        if (window.cordova.platformId.toLowerCase() === "android") {
          device = "androidApp";
        } else if (window.cordova.platformId.toLowerCase() === "ios") {
          device = "iosApp";
        }
      }
      analyticsService.trackEvent(null, "dispositivo", device);
      if (this.cart && this.cart.store && this.cart.store.name) {
        analyticsService.trackEvent(null, "coperativa", this.cart.store.name);
      }
    } finally {
      _this.loading = false;
    }
    //generate fingerprint
    // const fp = await FingerprintJS.load({ monitoring: false });
    // this.updateFingerprint(await fp.get());

    global.EventBus.$on("isNotLoggedIn", () => {
      _this.updateAuth();
      _this.handleLogin();
    });
    global.EventBus.$on("restart", () => {
      _this.updateLastStartTime();
      window.location.reload();
    });
    global.EventBus.$on("resetFilters", () => {
      _this.resetFilters(Math.random());
    });

    setTimeout(function() {
      _this.key = _this.key + 1;
    }, 3000);
    //eventi legati a cordova, partono solo al deviceready che viene scatenato da cordova quanto la app è pronta
    document.addEventListener(
      "deviceready",
      () => {
        setTimeout(function() {
          if (window.innerWidth < 600) {
            screen.orientation.lock("portrait");
          }
        }, 1500);

        //save last start time
        _this.updateLastStartTime();

        document.addEventListener("resume", _this.onResume, false);

        //TODO: cambiare gestione da config.js alla router clicked
        window.IonicDeeplink.route(
          global.config.deeplinks,
          function(match) {
            if (match.$link.path.indexOf("/shop/product/") > -1) {
              var prods = match.$link.path.split("/shop/");
              match.$link.path = "/" + prods[1];
            }
            if (match.$link.path.indexOf("/shop/category/") > -1) {
              var cats = match.$link.path.split("/shop/category/");
              match.$link.path = "/" + cats[1];
            }
            if (
              match.$link.path.indexOf(
                "/registration-card/registration_confirm"
              ) > -1
            ) {
              match.$link.path = "/registration/confirm/";
            }
            _this.resolveRoute(match.$link);
          },
          function(nomatch) {
            console.log("NOMATCH :::: ", nomatch);
            if (
              nomatch.$link.path.indexOf(
                "/registration-card/registration_confirm"
              ) > -1
            ) {
              nomatch.$link.path = "/registration/confirm/";
            }
            _this.resolveRoute(nomatch.$link);
          }
        );

        //con x secondi controllo se c'è una versione più recente
        this.checkVersion();

        this.isPlatformIos();

        if (this.isCordova) {
          window.open = window.cordova.InAppBrowser.open;
        }

        this.onAppTrackingTransparency();

        // Plugin Keyboard
        window.addEventListener("keyboardDidHide", () => {
          // Describe your logic which will be run each time keyboard is closed.
          console.log("keyboardDidHide");
          _this.keyboardOpen = false;
        });
        window.addEventListener("keyboardDidShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardDidShow");
          _this.keyboardOpen = true;
          if (
            _this.isCordova &&
            window.cordova.platformId.toLowerCase() === "ios"
          ) {
            document.activeElement.scrollIntoView();
          }
        });
        window.addEventListener("keyboardWillShow", event => {
          // Describe your logic which will be run each time when keyboard is about to be shown.
          console.log(event.keyboardHeight);
          console.log("keyboardWillShow");
          //iOS fix
          _this.keyboardOpen = true;
        });
        window.addEventListener("keyboardWillHide", () => {
          // Describe your logic which will be run each time when keyboard is about to be closed.
          console.log("keyboardWillHide");
          //iOS fix

          _this.keyboardOpen = false;
        });
        window.addEventListener(
          "CookiebotOnAccept",
          function() {
            console.log("CookiebotOnAccept");
            _this.attValue = true;
            // eslint-disable-next-line no-undef
            Cookiebot.consent;
            // eslint-disable-next-line no-undef
            console.log(Cookiebot.consent);
            // eslint-disable-next-line no-undef
            let platform = device ? device.platform.toLowerCase() : "ios";
            if (
              _this.$platform_is_cordova &&
              platform == "android" &&
              window.cordova.plugins.CookieManagementPlugin
            ) {
              window.cordova.plugins.CookieManagementPlugin.flush();
            }
          },
          false
        );
        window.addEventListener(
          "CookiebotOnDecline",
          function() {
            console.log("CookiebotOnDecline");
            _this.attValue = false;
            // eslint-disable-next-line no-undef
            Cookiebot.declined;
            // eslint-disable-next-line no-undef
            console.log(Cookiebot.declined);
            // eslint-disable-next-line no-undef
            let platform = device ? device.platform.toLowerCase() : "ios";
            if (
              _this.$platform_is_cordova &&
              platform == "android" &&
              window.cordova.plugins.CookieManagementPlugin
            ) {
              window.cordova.plugins.CookieManagementPlugin.flush();
            }
          },
          false
        );
        window.addEventListener(
          "CookiebotOnDialogDisplay",
          function() {
            console.log("Calling CookiebotOnDialogDisplay");
            // eslint-disable-next-line no-undef
            var hasResponded = Cookiebot.consented || Cookiebot.declined;
            if (
              window.cordova &&
              window.cordova.platformId.toLowerCase() == "ios" &&
              window.plugins.impacTracking &&
              !_this.attResponse
            ) {
              if (_this.attValue) {
                console.log("Ho cliccato su consenti");
                // Ho cliccato su "Consenti"
                // eslint-disable-next-line no-undef
                var stats = Cookiebot.consent.statistics;
                // eslint-disable-next-line no-undef
                var mrkg = Cookiebot.consent.marketing;
                // eslint-disable-next-line no-undef
                var pref = Cookiebot.consent.preferences;
                if (!hasResponded) {
                  console.log("Not responded");
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(true, true, true);
                  _this.attResponse = true;
                  // eslint-disable-next-line no-undef
                  Cookiebot.hide();
                } else {
                  console.log("Has responded");
                  // eslint-disable-next-line no-undef
                  Cookiebot.submitCustomConsent(pref, stats, mrkg);
                  _this.attResponse = true;
                }
              } else {
                console.log("Ho cliccato su non consentire");
                //Ho cliccato su "non consentire"
                // eslint-disable-next-line no-undef
                var declined = Cookiebot.declined;
                // eslint-disable-next-line no-undef
                Cookiebot.withdraw();
                _this.attResponse = true;
                if (!declined) {
                  console.log("First time");
                  //first time
                  // eslint-disable-next-line no-undef
                  Cookiebot.hide();
                }
              }
            }
          },
          false
        );

        this.refreshLogin();
      },
      false
    );
    if (this.isCordova) {
      //add platfrm to body element
      let platform = window.cordova.platformId;
      document.body.classList.add(platform);
    } else {
      //add platfrm to body element
      let platform = navigator.userAgentData
        ? navigator.userAgentData.platform
        : navigator.platform;
      document.body.classList.add(platform);
      this.checkOsx();
    }
  },
  mounted() {
    // Avoid loading Cookiebot if the page is server rendered (Prerender.io)
    // DOC: Prerender.io crawler User-Agent will always contain: "Prerender (+https://github.com/prerender/prerender)" - src:   https://docs.prerender.io/docs/33-overview-of-prerender-crawlers
    if (!navigator.userAgent.match("Prerender|GTmetrix|Googlebot")) {
      let cookiebotScript = document.createElement("script");
      cookiebotScript.setAttribute("id", "Cookiebot");
      cookiebotScript.setAttribute(
        "src",
        "https://consent.cookiebot.com/uc.js"
      );
      cookiebotScript.setAttribute(
        "data-cbid",
        process.env.VUE_APP_COOKIEBOT_CBID
      );
      cookiebotScript.setAttribute("type", "text/javascript");
      cookiebotScript.async = true;
      document.head.appendChild(cookiebotScript);
    }
  },
  beforeDestroy() {
    global.EventBus.$off("isNotLoggedIn");
    global.EventBus.$off("restart");
    global.EventBus.$off("resetFilters");
  },
  watch: {
    async drawerRight(newValue, oldValue) {
      if (newValue != oldValue) {
        if (newValue) {
          analyticsService.cartEvent("openCart", this?.cart?.cartItems);
        } else {
          analyticsService.cartEvent("closeCart", this?.cart?.cartItems);
        }
      }
    }
  }
};
</script>
