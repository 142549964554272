<template>
  <v-menu class="sso-user-card" bottom offset-y left v-if="isSsoAuthenticated">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        v-if="isSsoAuthenticated"
        fixed
        top
        right
        color="primary"
        class="sso-btn"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon large>$eye</v-icon>
      </v-btn>
    </template>
    <v-list class="sso-user-list">
      <v-list-item>
        Sei loggato come
      </v-list-item>
      <v-list-item>
        <strong>{{ isSsoUser.username }}</strong>
      </v-list-item>
      <v-divider class="divider"></v-divider>
      <v-list-item @click.stop="refresh()">
        <v-list-item-title v-text="'Refresh'"></v-list-item-title>
      </v-list-item>
      <v-list-item @click.stop="logout()">
        <v-list-item-title v-text="'Esci'"></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import split from "lodash/split";

export default {
  name: "SsoUserdCard",
  computed: {
    ...mapGetters({
      isSsoUser: "cart/isSsoUser",
      isSsoAuthenticated: "cart/isSsoAuthenticated"
    }),
    ...mapState({
      category: ({ category }) => category.category
    })
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    }),
    async logout() {
      console.log("TO-DO: Logout sso user");
    },
    async refresh() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });
      //await this.$router.go();
    }
  }
};
</script>
<style lang="scss">
.sso-user-list {
  z-index: 1500;
  .v-list {
    padding-bottom: 0px !important;
  }
  .v-list-item {
    min-height: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .v-divider {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}
.sso-btn {
  z-index: 10 !important;
  top: 160px !important;
  border-radius: 50% !important;
}
</style>
