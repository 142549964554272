<template>
  <div class="category-tree-container">
    <div class="header  d-flex d-md-none flex-column">
      <v-row no-gutters justify="space-between" class="ma-2">
        <img
          class="px-0 logo"
          :height="$vuetify.breakpoint.smAndDown ? '40px' : '60px'"
          src="/logo/logo.png"
          alt="Logo Coop Shop"
        />
        <v-btn
          depressed
          icon
          class="white pa-0"
          @click="$emit('close')"
          :aria-label="$t('common.close')"
        >
          <v-icon small>$close</v-icon>
        </v-btn>
      </v-row>
      <v-tabs
        v-model="selectedTab"
        class="px-2 mb-4"
        hide-slider
        icons-and-text
        centered
        active-class="activeTab"
        :height="60"
      >
        <v-tab key="categories" :tab-value="0">
          <span class="text-none">{{ $t("navbar.products") }}</span>
          <v-icon>$basket</v-icon>
        </v-tab>
        <v-tab key="user" v-if="isAuthenticated" :tab-value="1">
          <span class="text-none">{{ $t("navbar.user") }}</span>
          <v-icon>$profile</v-icon>
        </v-tab>
        <v-tab key="info" :tab-value="2">
          <span class="text-none">{{ $t("navbar.links") }}</span>
          <v-icon>$like</v-icon>
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item :value="0">
        <v-list class="category-tree px-2 px-sm-0">
          <template v-if="navbarItems && navbarItems.proposals">
            <v-list-item
              v-for="proposal in navbarItems.proposals"
              link
              :to="{
                name: 'Category',
                params: { pathMatch: proposal.slug }
              }"
              :key="proposal.proposalId"
              class="navbar-item d-flex d-md-none"
            >
              <v-list-item-avatar size="20" class="mr-2">
                <img
                  width="20"
                  height="20"
                  :src="proposal.img"
                  :alt="proposal.imgAlt"
                  :title="proposal.imgDescription"
                />
              </v-list-item-avatar>
              <v-list-item-title class="font-weight-bold">
                {{ proposal.descr }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list-group
            v-for="category in categories"
            :key="category.categoryId"
            class="category1"
            color="primary"
          >
            <template v-slot:activator>
              <v-list-item-avatar size="28" class="mr-2">
                <img
                  width="28"
                  height="28"
                  :alt="category.name"
                  :src="$ebsn.meta(category, 'category_info.ICON')"
                />
              </v-list-item-avatar>
              <v-list-item-title class="font-weight-bold">
                <router-link
                  :aria-label="`${category.slug}`"
                  :title="`${category.slug}`"
                  :to="{
                    name: 'Category',
                    params: { pathMatch: category.slug }
                  }"
                  event
                  @click.native.prevent="nop"
                >
                  {{ category.name }}
                </router-link>
              </v-list-item-title>
            </template>
            <template v-for="category2 in category.categories">
              <v-list-item
                link
                :to="{
                  name: 'Category',
                  params: { pathMatch: category2.slug }
                }"
                class="category2"
                :key="category2.categoryId"
              >
                <v-list-item-title>{{ category2.name }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>
        </v-list>
      </v-tab-item>
      <v-tab-item :value="1" v-if="isAuthenticated">
        <Dashboard class="px-2" :drawer="true" />
      </v-tab-item>
      <v-tab-item :value="2">
        <v-list class="info-tab px-2">
          <template v-for="(item, primaryLinkIdx) in primaryLinks">
            <v-list-item
              v-if="item.templateNavItems?.length <= 0"
              :key="primaryLinkIdx"
              class="menuItem"
              :href="item.itemUrl"
              @click="clicked"
            >
              <v-list-item-title class="ml-0" :class="item.classes">
                {{ item.name }}
              </v-list-item-title>
            </v-list-item>
            <v-list-group
              class="menuItem"
              v-else
              :key="primaryLinkIdx"
              color="primary"
            >
              <template v-slot:activator>
                <v-list-item-title>
                  <a
                    :href="item.itemUrl"
                    event
                    :aria-label="item.itemUrl"
                    :title="item.itemUrl"
                  >
                    {{ item.name }}
                  </a>
                </v-list-item-title>
              </template>
              <div
                v-for="(child, childIdx) in item.templateNavItems"
                :key="childIdx"
              >
                <v-list-item link :href="child.itemUrl" @click="clicked">
                  <v-list-item-title class="ml-1">
                    {{ child.name }}
                  </v-list-item-title>
                </v-list-item>
                <v-list
                  class="py-0"
                  dense
                  v-if="child.templateNavItems?.length"
                >
                  <v-list-item
                    v-for="(child2, idx3) in child.children"
                    :key="idx3"
                    link
                    :href="child2.itemUrl || '#'"
                    @click="clicked"
                    class="text-caption default--text ml-3"
                    :class="child2.classes"
                  >
                    <span :class="child2.classes">{{ child2.name }}</span>
                  </v-list-item>
                </v-list>
              </div>
            </v-list-group>
          </template>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import deliveryReactive from "~/mixins/deliveryReactive";
import { mapState, mapGetters, mapActions } from "vuex";
import clickHandler from "~/mixins/clickHandler";

import Dashboard from "@/views/profile/Dashboard.vue";
export default {
  name: "CategoryTree",
  mixins: [deliveryReactive, clickHandler],
  props: {
    primaryLinks: { type: Array },
    navbarItems: { type: Object, default: () => {} },
    category: { type: Object }
  },
  components: { Dashboard },
  data() {
    return {
      expandedKeys: [],
      selectedKey: null
    };
  },
  computed: {
    ...mapState({
      categories: ({ category }) => category.categoryTree
    }),
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      getMenuPositionModel: "custom/getMenuPositionModel"
    }),
    selectedTab: {
      get() {
        return this.getMenuPositionModel;
      },
      set(value) {
        this.$store.commit("custom/updateMenuPositionModel", value);
      }
    }
  },
  methods: {
    ...mapActions({
      reload: "category/loadCategoryTree"
    }),
    close() {
      this.$emit("close");
    },
    nop() {}
  },
  created() {
    this.reload(true);
  }
};
</script>

<style scoped lang="scss">
.category-tree-container {
  background-repeat: no-repeat;
  background-position: bottom right;
  .navbar-item {
    border-bottom: 1px solid #d4d4d4;
  }
  .header {
    .v-tabs {
      position: relative;
      top: 1px;
      z-index: 1;
      :first-child {
        margin-right: 2px;
      }
      :last-child {
        margin-left: 2px;
      }
      .v-tab {
        min-width: 30%;
        height: 60px;
        color: var(--v-default-base) !important;
        background-color: transparent;
        border-radius: 4px;
        span {
          margin-top: 5px;
          margin-bottom: 0;
          color: var(--v-default-base) !important;
        }
      }
      .v-tabs-slider-wrapper {
        color: transparent !important;
      }
      .v-tab--active {
        background-color: var(--v-primary-base);
        .v-icon {
          color: $white;
        }
        span {
          color: $white !important;
        }
        &::before {
          opacity: 0;
        }
      }
    }
  }
  .menuItem .v-list-item {
    padding-left: 0px;
  }
}
.category1 {
  :deep(.v-list-item__icon.v-list-group__header__append-icon) {
    margin-left: 0px;
    min-width: 30px;
    .v-icon {
      font-size: 15px;
    }
  }
}
.category-tree {
  padding: 0;
  a {
    text-decoration: none;
  }
  .v-list-group {
    border-bottom: 1px solid $border-color;
  }
  .category1 {
    a {
      color: $text-color;
      font-size: 14px;
    }
    &.v-list-item--active {
      a {
        color: var(--v-primary-base);
        ::before {
          opacity: 0;
        }
      }
      &.v-list-group__header {
        color: var(--v-primary-base);
        ::before {
          opacity: 0;
        }
      }
    }
    .v-list-item__title {
      padding-left: 0;
      color: $text-color;
    }
  }
  .category2 {
    .v-list-item__title {
      font-size: 14px;
      text-transform: initial;
      color: $text-color !important;
    }
    &.v-list-item--active {
      background-color: transparent;
      color: transparent;
      .v-list-item__title {
        color: $text-color;
        font-weight: bold;
      }
      &:before {
        opacity: 1;
      }
    }
    &.v-list-item--link {
      padding-left: 34px;
    }
  }
}

.info-tab {
  padding: 0;
  .link {
    min-height: 40pt;
    .v-list-item__icon {
      margin: 0 !important;
      align-self: center;
      font-size: 30px;
    }
    .v-list-item__title {
      margin-left: 16px;
    }
  }
  .menuItem:has(div.v-list-group) {
    padding-left: 0px;
  }
  .menuItem {
    min-height: 40pt;
    padding-right: 0;
    border-bottom: 1px solid $border-color;
    .v-list-item__icon {
      height: 50pt;
      margin: 0 !important;
      font-size: 30px;
    }
    .v-list-item__title {
      font-weight: bold;
      a {
        color: $text-color;
        font-size: 14px;
        text-decoration: none;
      }
    }
    .v-list-group {
      width: 100%;
      &__items .v-list-item {
        padding-left: 16px;
      }

      .v-list-group__header {
        padding-right: 0 !important;
        min-height: 50pt;
        .v-list-item__title {
          margin-left: 0;
        }
      }
    }
    .v-list-group--active {
      .v-list-group__header {
        .v-list-item__title {
          a {
            color: var(--v-primary-base) !important;
          }
        }
      }
    }
    .v-list-item--active {
      height: 50pt;
    }
  }
}
</style>
